/* Container Styling */
.faq-container {
    max-width: 1400px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .faq-title {
    font-size: 32px;
    text-align: center;
    color: #1e40af;
    margin-bottom: 20px;
    font-family: 'Arial', sans-serif;
  }
  
  /* FAQ Items Container */
  .faq-items-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }
  
  /* FAQ Item Styling */
  .faq-item {
    padding: 20px; /* Add consistent padding for spacing */
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: all 0.3s ease;
    text-align: center; /* Center-align text within the item */
  }
  
  .faq-item.open {
    border: 2px solid #1e40af;
  }
  
  .faq-question {
    font-size: 18px;
    font-weight: 600;
    color: #1e40af;
    display: flex;
    justify-content: center; /* Center question text horizontally */
    align-items: center; /* Center question text vertically */
    gap: 10px; /* Adds space between the question and icon */
  }
  
  .faq-question span {
    font-size: 24px;
    color: #1e40af;
  }
  
  .faq-answer {
    margin-top: 10px;
    font-size: 14px;
    color: #555;
    line-height: 1.6;
    text-align: center; /* Center-align answer text */
  }
  
  /* Hover Effects */
  .faq-item:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .faq-items-container {
      grid-template-columns: 1fr; /* Stacks items for smaller screens */
    }
  
    .faq-title {
      font-size: 28px;
    }
  }
  