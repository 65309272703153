.about {
    margin-bottom: 4.5rem;
  }
  
  .aboutTitle {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 4.5rem;
    margin-bottom: 3rem;
  }
  
  .aboutTitle h3 {
    color: #181717;
    font-size: 2rem;
  }
  
  .aboutTitle h5 {
    color: #149c90;
    font-size: 1rem;
    font-weight: 700;
    font-family: "Dancing Script", cursive;
    line-height: 1.5rem;
  }
  
  .timeline {
    position: relative;
    max-width: 75rem;
    margin: auto;
  }
  
  .container {
    position: relative;
    padding: 0.6rem 3.1rem;
    width: 50%;
    margin-bottom: 3rem;
  }
  
  .leftContainer {
    left: 0;
  }
  
  .rightContainer {
    left: 50%;
  }
  
  .textBox {
    padding: 1.2rem 1.8rem;
    background: #eee;
    position: relative;
    border-radius: 0.3rem;
    font-size: 1rem;
  }
  
  .circle {
    position: absolute;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    top: 2rem;
    color: #1E3A8A;
    z-index: 1;
  }
  
  .rightContainer .circle {
    left: -1.2rem;
  }
  
  .leftContainer .circle {
    right: -1.2rem;
  }
  
  .timeline::after {
    content: "";
    position: absolute;
    width: 0.3rem;
    height: 100%;
    background: #1E3A8A;
    top: 0;
    left: 50%;
    margin-left: -0.15rem;
    z-index: -1;
  }
  
  .textBox h2 {
    font-weight: 600;
    color: #211f1f;
    margin-bottom: 0.2rem;
  }
  
  .textBox small {
    display: inline-block;
    margin-bottom: 0.8rem;
    color: #1E3A8A;
  }
  
  .textBox p {
    color: #373434;
  }
  
  .leftContainerArrow {
    height: 0;
    width: 0;
    position: absolute;
    top: 1.7rem;
    z-index: 1;
    border-top: 0.9rem solid transparent;
    border-bottom: 0.9rem solid transparent;
    border-left: 0.9rem solid #eee;
    right: -0.9rem;
  }
  
  .rightContainerArrow {
    height: 0;
    width: 0;
    position: absolute;
    top: 1.7rem;
    z-index: 1;
    border-top: 0.9rem solid transparent;
    border-bottom: 0.9rem solid transparent;
    border-right: 0.9rem solid #eee;
    left: -0.9rem;
  }
  
  /* Media Queries for Responsiveness */
  @media screen and (max-width: 767px) {
    .timeline {
      max-width: 100%;
      padding: 0 1rem; /* Add some padding for small devices */
    }
  
    .container {
      width: 100%; /* Make the containers full-width on small screens */
      padding: 1rem 0; /* Adjust padding for small screens */
    }
  
    .leftContainer,
    .rightContainer {
      left: 0;
      margin: 1rem 0; /* Give some space between the elements */
    }
  
    .circle {
      width: 2rem;
      height: 2rem;
      top: 1.5rem; /* Adjust circle positioning */
    }
  
    .textBox {
      padding: 1rem;
    }
  
    .leftContainerArrow,
    .rightContainerArrow {
      display: none; /* Hide arrows on small screens */
    }
  
    .textBox h2 {
      font-size: 1.2rem; /* Adjust heading font size for smaller screens */
    }
  
    .textBox small {
      font-size: 0.9rem; /* Adjust small text size */
    }
  }